<template>
  <div class="login">
    <div class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <h1 class="title is-size-2">Login</h1>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="columns is-centered">
        <div class="column">
          <form action="" @submit.prevent="submit" class="box">
            <div class="field">
              <label for="" class="label">Email</label>
              <div class="control has-icons-left">
                <input
                    type="email"
                    placeholder="e.g. bobsmith@gmail.com"
                    class="input"
                    v-model="email"
                    autofocus
                    required
                    id="email"
                    name="email"
                >
                <span class="icon is-small is-left">
                    <i class="fa fa-envelope"></i>
                  </span>
              </div>
            </div>
            <div class="field">
              <label for="" class="label">Password</label>
              <div class="control has-icons-left">
                <input
                    type="password"
                    placeholder="*******"
                    class="input"
                    v-model="password"
                    id="password"
                    name="password"
                    required
                >
                <span class="icon is-small is-left">
                    <i class="fa fa-lock"></i>
                  </span>
              </div>
            </div>
            <div class="has-text-centered">
              <p class="is-size-7"> Don't have an account? <a href="#" class="has-text-primary">
                <router-link to="/register">Sign Up</router-link>
              </a>
              </p>
            </div>
            <div class="field">
              <button type="submit" class="button is-success">
                Login
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase';
import {useUserStore} from "../store";

export default {
  setup() {
    let store = useUserStore()
    return {store}
  },
  data() {
    return {
      email: "",
      password: ""
    }
  },
  methods: {
    submit() {
      firebase
          .auth()
          .signInWithEmailAndPassword(this.email, this.password)
          .then(() => {
            this.store.login(this.email)
            if(this.$route.query.hasOwnProperty("redirect")){
              this.$router.push(this.$route.query.redirect)
            }
            else{
              this.$router.push("/");
            }
          })
          .catch(err => {
            this.$swal({icon: 'error', title: "Oops...", text: err.message});
            this.error = err.message;
          });
    },
  }
};
</script>
<style lang="scss" scoped>
.hero-body {
  background: #ed213a; /* fallback for old browsers */
  background: -webkit-linear-gradient(
          to right,
          #93291e,
          #ed213a
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
          to right,
          #93291e,
          #ed213a
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  margin-bottom: 40px;
}

.hero.is-primary {
  background-color: transparent;
}
</style>